import FullScreenLoader from '@libs/atoms/fullscreen-loader';
import Input from '@libs/atoms/Input';
import OverlayLoader from '@libs/atoms/OverlayLoader';
import Pagination from '@libs/atoms/Pagination/Pagination';
import PropertyControlledComponent from '@libs/atoms/PropertyControlledComponent';
import { Search } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { PROJECTS_LIMIT } from 'components/Newproject/newproject.types';
import TableModal from 'components/TableModal';
import ProjectCard from 'containers/ProjectCard/ProjectCard';
import { motion } from 'framer-motion';
import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomerAuth from 'services/auth';
import { updateAnnotationModal } from 'slices/ProjectSlice';
import { useGetAnnotationProjects_v2Query } from 'store/api/slices/getApis/getApisSlice';
import { RootState } from 'store/AppStore';
import { initAmplitude } from 'utils/amplitude';
import { dateFormat } from 'utils/common';

import styles from './TrainingApp.module.scss';

export default function TrainingApp() {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);

  // startDate and endDate are both set or both unset
  const bothDatesSet = Boolean(startDate) === Boolean(endDate);

  const {
    data: projects,
    isFetching,
    refetch
  } = useGetAnnotationProjects_v2Query(
    {
      skip: Math.max(page - 1, 0) * PROJECTS_LIMIT,
      limit: PROJECTS_LIMIT,
      title: search,
      start_time: bothDatesSet ? dateFormat(startDate) : null,
      end_time: bothDatesSet ? dateFormat(endDate) : null
    },
    {
      skip: !CustomerAuth.isAuthenticated
    }
  );

  const dispatch = useDispatch();

  const showAnnotationModal = useSelector((state: RootState) => state.project.showAnnotationModal);

  const UserInfo = useSelector((state: RootState) => {
    return state.user.UserDetails;
  });

  const handleOnValueChange = useCallback((event) => {
    setSearch(event.target.value);
    setPage(0);
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };

  const handleEndDateChange = (e) => {
    if (!startDate) {
      alert('Please select start date first');
      return;
    }
    if (startDate && new Date(e.target.value) < startDate) {
      alert('End date should be greater than start date');
      return;
    }
    setEndDate(new Date(e.target.value));
    setPage(0);
  };

  const debouncedResults = useMemo(
    () => _debounce(handleOnValueChange, 400),
    [handleOnValueChange]
  );

  const handleClearState = () => {
    setSearch('');
    setStartDate(null);
    setEndDate(null);
    setPage(0);
  };

  useEffect(() => {
    if (CustomerAuth.isAuthenticated) {
      const { userInfo } = CustomerAuth;
      if (userInfo) {
        initAmplitude(userInfo);
      }
    }
  }, [UserInfo]);

  const isMediaUploading = useSelector((state: RootState) => {
    return state.status.loading;
  });

  return (
    <div>
      <div className="flex h-[80vh] flex-col justify-start ">
        <div className="flex w-full items-center gap-2">
          <div className="w-full flex-1">
            <Input
              className={styles.inputBar}
              variant="default"
              status="normal"
              placeholder="Search"
              width="100%"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="m-0">
                    <Search />
                  </InputAdornment>
                )
              }}
              autoComplete="off"
              onChange={debouncedResults}
              overRideStyles={{
                borderRadius: '18px !important',
                height: '40px',
                color: 'white'
              }}
            />
          </div>
          <Tooltip title="Start Date">
            <input
              type="date"
              id="start-date"
              placeholder="Start Date"
              className="bg-white/20 text-white"
              value={startDate ? startDate.toISOString().split('T')[0] : ''}
              onChange={handleStartDateChange}
            />
          </Tooltip>
          <Tooltip title="End Date">
            <input
              type="date"
              placeholder="End Date"
              id="end-date"
              className="bg-white/20 text-white"
              value={endDate ? endDate.toISOString().split('T')[0] : ''}
              onChange={handleEndDateChange}
            />
          </Tooltip>
          <button className="text-white" onClick={handleClearState}>
            Clear Filters
          </button>
        </div>
        <PropertyControlledComponent
          fallback={<FullScreenLoader />}
          controllerProperty={!(CustomerAuth.isAuthenticated && isFetching)}
        >
          <>
            <OverlayLoader open={isMediaUploading} />
            <div className={styles.projectList}>
              {_map(projects?.data, (project) => (
                <motion.div
                  key={project.annotation_project_id}
                  exit={{ y: '50%', opacity: 0 }}
                  transition={{ duration: 0.5, ease: 'easeOut' }}
                  initial={{ opacity: 0, y: '50%', scale: 0.5 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                >
                  <ProjectCard project={project} refetch={refetch} />
                </motion.div>
              ))}
            </div>
          </>
        </PropertyControlledComponent>
      </div>
      <div className="flex w-full items-center justify-center text-white">
        <Pagination
          isLoading={isMediaUploading}
          page={page}
          totalPages={Math.ceil((projects?.size || 0) / PROJECTS_LIMIT)}
          className={styles.pagination}
          handleOnChange={(_, newPage) => {
            setPage(newPage);
          }}
        />
      </div>
      <TableModal
        open={showAnnotationModal}
        onClose={() => dispatch(updateAnnotationModal(false))}
      />
    </div>
  );
}
